import { Context } from "../../index";
import { DialogButton } from "../../state";
import { PromoType } from "./state";
import { create } from "domain";

export const initPromos = async ({ state, effects, actions }: Context) => {
  try {
    const { promo_type } = await effects.gql.queries.getPromoTypeEnums({});
    state.promos.promoTypes = promo_type;
    promo_type.forEach(
      ({ value, comment }: { value: string; comment: string }) => {
        state.promos.promoTypesMap[value] = {
          value,
          comment
        };
      }
    );
  } catch (error) {}
};

export const getPromos = async ({ state, effects, actions }: Context) => {
  try {
    const { promotion } = await effects.gql.queries.listPromos({});
    state.promos.list = promotion.map(
      ({
        id,
        name,
        description,
        expired_at,
        created_at,
        does_not_expire,
        limit,
        promo_code,
        promo_type,
        sibilings_promotion_id,
        is_active
      }: {
        id: string;
        name: string;
        description: string;
        expired_at: string | null | undefined;
        does_not_expire: boolean;
        limit: number;
        promo_code: string;
        promo_type: string;
        created_at: string;
        sibilings_promotion_id: string[];
        is_active: boolean;
      }) => {
        const P = {
          id,
          name,
          description,
          expiredAt: expired_at,
          doesNotExpire: does_not_expire,
          limit: limit,
          promoCode: promo_code,
          promoType: promo_type,
          createdAt: created_at,
          siblingIds: sibilings_promotion_id,
          isActive: is_active
        };
        state.promos.listIdMap[id] = P;
        return P;
      }
    );
  } catch (error) {
    console.error(error);
  }
};

export const groupPromos = async ({ state, effects, actions }: Context) => {
  try {
    const sibilings_promotion_id = state.promos.selectedIndex.map(
      index => state.promos.list[index].id
    );

    state.promos.selectedIndex.forEach(async index => {
      const { id } = state.promos.list[index];
      const { update_promotion_by_pk } = await effects.gql.mutations.setSibling(
        {
          id,
          sibilings_promotion_id:
            sibilings_promotion_id.length > 1 ? sibilings_promotion_id : ""
        }
      );
      console.log("update_promotion_by_pk", update_promotion_by_pk);
    });

    state.promos.list.forEach((a, index) => {
      state.promos.list[index].selected = false;
      // @ts-ignore
      document.getElementsByClassName("checkbox-" + index)[0].checked = false;
    });

    setTimeout(() => {
      actions.promos.getPromos();
    }, 1000);
  } catch (error) {
    console.log(error);
  }
};

export const setSelectedPromoIndex = async (
  { state, effects, actions }: Context,
  { index, checked }: { index: number; checked: boolean }
) => {
  try {
    state.promos.list[index].selected = checked;
  } catch (error) {}
};
export const setIsActive = async (
  { state, effects, actions }: Context,
  { id, isActive }: { id: string; isActive: boolean }
) => {
  try {
    const { update_promotion_by_pk } = await effects.gql.mutations.setIsActive({
      id,
      isActive
    });
    actions.promos.getPromos();
  } catch (error) {}
};

export const createPromo = async (
  { state, effects, actions }: Context,
  {
    name,
    description,
    expiredAt,
    doesNotExpire,
    limit,
    promoCode,
    promoType
  }: {
    name: string;
    description: string;
    expiredAt: string;
    doesNotExpire: string | null | undefined; // "doesNotExpire" | null
    limit: number;
    promoCode: string;
    promoType: PromoType;
  }
) => {
  try {
    const { insert_promotion } = await effects.gql.mutations.createPromo({
      name,
      description,
      expiredAt,
      doesNotExpire: typeof doesNotExpire === "string",
      limit,
      promoCode,
      promoType
    });
    actions.toast({ message: "Created a Promo" });
    actions.nav({ route: "/promos/list" });
  } catch (error) {
    actions.toast({ error });
  }
};

export const getRedeemHistory= async ({ state, effects, actions }: Context) => {
  try {
    const { redeem } = await effects.gql.queries.getRedeemHistory({});
    state.promos.redeemHistory = redeem;
  } catch (error) {
    console.error(error);
  }
};
