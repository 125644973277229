import LoginPage from "./LoginPage";
import RootPage from "./RootPage";
import PromosListPage from "./PromosListPage";
import PromosCreatePage from "./PromosCreatePage";
import RedeemHistoryPage from "./RedeemHistoryPage";

export default [
  { Page: LoginPage, route: "/login" },
  { Page: RootPage, route: "/" },
  { Page: PromosListPage, route: "/promos/list" },
  { Page: PromosCreatePage, route: "/promos/create" },
  { Page: RedeemHistoryPage, route: "/redeem/history" }
];
