import apiFactory, { Api, ApiConfig, Params } from "./apiFactory";

let api: Api;

export default {
  initialize(config: ApiConfig) {
    api = apiFactory(config);
  },
  async login({
    email,
    password
  }: {
    email: string;
    password: string;
  }): Promise<{ token: string; isSuperAdmin: boolean | undefined }> {
    const response = await api.post<{
      token: string;
      isSuperAdmin: boolean | undefined;
    }>(email === "team@switch168.com" ? "/loginSuperAdmin" : "/login", {
      email,
      password
    });
    return {
      token: response.token,
      isSuperAdmin: response.isSuperAdmin
    };
  },
  async version(): Promise<string> {
    const response = await api.get<{ version: string }>("/version");

    return response.version;
  }
};
