import React from "react";
import { useActions, useAppState } from "../main";
import SlButton from "@shoelace-style/react/dist/button";
import SlForm from "@shoelace-style/react/dist/form";
import SlInput from "@shoelace-style/react/dist/input";

const LoginPage: React.FunctionComponent = () => {
  const { login } = useActions();

  return (
    <>
      <SlForm
        onSlSubmit={e => {
          // @ts-ignore
          const password = e.detail.formData.get("password");
          // @ts-ignore
          const email = e.detail.formData.get("email");

          login({ email, password });
        }}
      >
        <SlInput
          name="email"
          label="Email"
          type="email"
          placeholder="Username"
          required
        />
        <br />
        <SlInput
          name="password"
          label="Password"
          type="password"
          placeholder="Password"
          toggle-password
          required
        />
        <br />
        <SlButton submit>Submit</SlButton>
      </SlForm>
    </>
  );
};

export default LoginPage;
