import React from "react";
import { useActions, useAppState } from "../main";
import SlButton from "@shoelace-style/react/dist/button";
import SlForm from "@shoelace-style/react/dist/form";
import SlInput from "@shoelace-style/react/dist/input";

const LoginPage: React.FunctionComponent = () => {
  const { login } = useActions();

  return (
    <>
      <div>Welcome</div>
    </>
  );
};

export default LoginPage;
