import { derived } from "overmind";

export type PromoType = {
  value: string;
  comment: string;
};

type Promos = {
  id: string;
  name: string;
  description: string;
  expiredAt: Date | null | undefined;
  doesNotExpire: string; // "doesNotExpire" | null
  limit: number;
  promoCode: string;
  promoType: string;
  createdAt: Date | null | undefined;
  selected: boolean | null | undefined;
  siblingIds: string[];
  isActive: boolean;
};

type Redeem = {
  state: string;
  promotion_id: string;
  promo_type: string;
  phone_number: string;
  id: string;
  created_at: Date
}

type State = {
  promoTypes: PromoType[];
  promoTypesMap: any;
  list: Promos[];
  listIdMap: any;
  selectedIndex: number[];
  redeemHistory: Redeem[];
};

export const state: State = {
  promoTypes: [],
  promoTypesMap: {},
  list: [],
  listIdMap: {},
  selectedIndex: derived((state: State, rootState) => {
    const indexes: number[] = [];
    state.list.forEach(({ selected }, index) => {
      selected && indexes.push(index);
    });

    return indexes;
  }),
  redeemHistory: []
};
