import { graphql } from "overmind-graphql";

import * as promosQueries from "./promos/queries";
import * as promosMutations from "./promos/mutations";

export default graphql({
  subscriptions: {},
  queries: {
    ...promosQueries
  },
  mutations: {
    ...promosMutations
  }
});
