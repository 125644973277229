// @ts-ignore
import { gql } from "overmind-graphql";

// @ts-ignore
export const createPromo = gql`
  mutation createPromo(
    $description: String = ""
    $doesNotExpire: Boolean = false
    $expiredAt: timestamptz = ""
    $limit: Int = 10
    $name: String = ""
    $promoCode: String = ""
    $promoType: promo_type_enum = credits
  ) {
    insert_promotion(
      objects: {
        description: $description
        does_not_expire: $doesNotExpire
        expired_at: $expiredAt
        limit: $limit
        name: $name
        promo_code: $promoCode
        promo_type: $promoType
      }
    ) {
      affected_rows
    }
  }
`;

// @ts-ignore
export const setSibling = gql`
  mutation MyMutation($id: uuid = "", $sibilings_promotion_id: jsonb = "") {
    update_promotion_by_pk(
      pk_columns: { id: $id }
      _set: { sibilings_promotion_id: $sibilings_promotion_id }
    ) {
      id
    }
  }
`;

// @ts-ignore
export const setIsActive = gql`
  mutation MyMutation($id: uuid = "", $isActive: Boolean = false) {
    update_promotion_by_pk(
      pk_columns: { id: $id }
      _set: { is_active: $isActive }
    ) {
      id
    }
  }
`;
