import React from "react";

import { css, menu } from "../stitches.config";
import SlMenu from "@shoelace-style/react/dist/menu";
import SlMenuItem from "@shoelace-style/react/dist/menu-item";
import SlDivider from "@shoelace-style/react/dist/divider";
import { useActions, useAppState } from "../main";
import { RunMode } from "../main/state";

const sidebar = css({
  height: "100%",
  paddingLeft: "1rem"
  // margin: "0.5rem"
});

const RightSidebar: React.FunctionComponent = () => {
  const { groupPromos } = useActions().promos;

  const Menu = () => (
    <div>
      <SlMenu className={menu()}>
        <SlMenuItem
          onClick={() => {
            groupPromos();
          }}
        >
          Group Promos
        </SlMenuItem>
        <SlDivider />
      </SlMenu>
    </div>
  );

  return (
    <aside className={sidebar()}>
      <Menu />
    </aside>
  );
};

export default RightSidebar;
