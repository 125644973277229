import React from "react";
import { useActions, useAppState } from "../main";
import SlButton from "@shoelace-style/react/dist/button";
import SlForm from "@shoelace-style/react/dist/form";
import SlInput from "@shoelace-style/react/dist/input";
import SlTextarea from "@shoelace-style/react/dist/textarea";
import SlDivider from "@shoelace-style/react/dist/divider";
import SlCheckbox from "@shoelace-style/react/dist/checkbox";
import SlSelect from "@shoelace-style/react/dist/select";
import SlMenuItem from "@shoelace-style/react/dist/menu-item";

const PromosCreatePage: React.FunctionComponent = () => {
  const { createPromo } = useActions().promos;
  const { promoTypes } = useAppState().promos;

  return (
    <>
      <SlForm
        onSlSubmit={e => {
          // @ts-ignore
          const name = e.detail.formData.get("name");
          // @ts-ignore
          const description = e.detail.formData.get("description");
          // @ts-ignore
          const expiredAt = e.detail.formData.get("expiredAt");
          // @ts-ignore
          const doesNotExpire = e.detail.formData.get("doesNotExpire");
          // @ts-ignore
          const limit = e.detail.formData.get("limit");
          // @ts-ignore
          const promoType = e.detail.formData.get("promoType");
          // @ts-ignore
          const promoCode = e.detail.formData.get("promoCode");

          // console.log({ name, description, expiredAt, doesNotExpire });
          createPromo({
            name,
            description,
            expiredAt,
            doesNotExpire,
            limit,
            promoType,
            promoCode
          });
        }}
      >
        <SlInput
          name="name"
          label="Name"
          type="text"
          placeholder="Name"
          required
        />
        <SlDivider />
        <SlTextarea
          name="description"
          label="Description"
          placeholder="Description"
          required
        />
        <SlDivider />
        <SlInput
          name="expiredAt"
          label="Expired At"
          type="date"
          placeholder="Expired At"
          required
        />
        <SlDivider />
        <SlCheckbox name="doesNotExpire" value="doesNotExpire">
          Does not expire
        </SlCheckbox>
        <SlDivider />
        <SlInput
          name="limit"
          label="Limit"
          type="number"
          placeholder="Limit"
          required
        />
        <SlDivider />
        <SlDivider />
        <SlInput
          name="promoCode"
          label="Promo Code"
          type="text"
          placeholder="Promo Code (eg. TEST2021)"
          required
        />
        <SlDivider />
        <SlSelect label="Promo Type" placeholder="Select One" name="promoType">
          {promoTypes.map(({ value, comment }) => (
            <SlMenuItem value={value}>{comment}</SlMenuItem>
          ))}
        </SlSelect>
        <SlDivider />
        <SlButton submit>Submit</SlButton>
      </SlForm>
    </>
  );
};

export default PromosCreatePage;
