import { createStitches } from "@stitches/react";

export const { styled, css } = createStitches({
  media: {
    sm: "(min-width: 640px)",
    md: "(min-width: 768px)",
    lg: "(min-width: 1024px)"
  }
});

export const fillHeight = css({
  height: "96vh",
  flexGrow: 1,
  overflowY: "scroll"
});

export const comlist = css({
  width: "100%",
  marginBottom: "1em"
});

export const header = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
});

export const menu = css({
  height: "96vh",
  border: "solid 1px rgb(var(--sl-panel-border-color))",
  borderRadius: "var(--sl-border-radius-medium)"
});

export const padr = css({
  paddingRight: "0.5rem"
});

export const fullWidthTable =  {
  main: css({
    display: "block",
    maxWidth: "100%"
  }),
  tableWrap: css({
    display: "block",
    maxWidth: "100%",
    overflowX: "scroll",
    overflowY: "hidden",
    borderBottom: "1px solid black"
  }),
  table: css({
    width: "100%",
    borderSpacing: 0,

  }),
  td: css({
    margin: 0,
    padding: "0.5rem",
    borderBottom: "1px solid black",
    borderRight: "1px solid black",
    width: "1%",
  })
}
