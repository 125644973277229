import React from "react";
import { useActions, useAppState } from "../main";
import SlCard from "@shoelace-style/react/dist/card";

import { comlist, fillHeight, header } from "../stitches.config";
import SlIconButton from "@shoelace-style/react/dist/icon-button";
import Time from "../components/Time";
import SlCheckbox from "@shoelace-style/react/dist/checkbox";
import RightSidebar from "../components/RightSidebar";

const PromosListPage: React.FunctionComponent = () => {
  const { getPromos, setSelectedPromoIndex, setIsActive } = useActions().promos;
  const { list, listIdMap, promoTypes, promoTypesMap } = useAppState().promos;

  React.useEffect(() => {
    getPromos();
  }, []);
  return (
    <>
      <div className={fillHeight()}>
        <div className={comlist()}>
          {list.map(
            (
              {
                name,
                description,
                id,
                expiredAt,
                createdAt,
                promoType,
                promoCode,
                siblingIds,
                isActive
              },
              index
            ) => {
              return (
                <SlCard className={comlist()}>
                  <span className={header()} slot="header">
                    <SlCheckbox
                      className={"checkbox-" + index}
                      onSlChange={({ target }) => {
                        setSelectedPromoIndex({
                          index: index,
                          // @ts-ignore
                          checked: target.checked
                        });
                      }}
                    />
                    <strong>{isActive ? "ACTIVE" : "NOT-ACTIVE"}</strong>
                    <strong>
                      {name} ({promoCode})
                    </strong>
                    {/*<SlIconButton*/}
                    {/*  onClick={() => console.log({ id })}*/}
                    {/*  name="list"*/}
                    {/*  label="Manage"*/}
                    {/*/>*/}
                    <SlIconButton
                      onClick={() => setIsActive({ id, isActive: !isActive })}
                      name="check-circle"
                      label="Set Active"
                    />
                  </span>
                  <p>Promo Code: {promoCode}</p>
                  <p>
                    <small>Pass promo code around to the users</small>
                  </p>
                  <p>Description: {description}</p>
                  <p>
                    {promoType} ({promoTypesMap[promoType].comment})
                  </p>

                  <p>
                    Grouped With:{" "}
                    {siblingIds &&
                      siblingIds.length &&
                      siblingIds.map(id => (
                        <li>
                          {listIdMap[id].name} ({listIdMap[id].promoCode})
                        </li>
                      ))}
                  </p>

                  <Time label="Created at:" time={createdAt} />
                  <br />
                  <Time label="Expired at:" time={expiredAt} />
                </SlCard>
              );
            }
          )}
        </div>
      </div>
      <RightSidebar />
    </>
  );
};

export default PromosListPage;
