import { IContext } from "overmind";
import {
  createActionsHook,
  createEffectsHook,
  createReactionHook,
  createStateHook
} from "overmind-react";

import { merge, namespaced } from "overmind/config";

import * as actions from "./actions";
import * as effects from "./effects";
import * as promos from "./namespaces/promos";

import { state } from "./state";

export const config = merge(
  {
    effects,
    state,
    actions
  },
  namespaced({
    promos,
  })
);

export type Context = IContext<{
  state: typeof config.state;
  actions: typeof config.actions;
  effects: typeof config.effects;
}>;

export const useAppState = createStateHook<Context>();
export const useActions = createActionsHook<Context>();
export const useEffects = createEffectsHook<Context>();
export const useReaction = createReactionHook<Context>();
// export const useApp = createHook<typeof config>();
