import { gql } from "overmind-graphql";

// @ts-ignore
export const getPromos = gql`
  query getPromos {
    promotions(where: {}) {
      id
    }
  }
`;

// @ts-ignore
export const getPromoTypeEnums = gql`
  query getPromoTypeEnums {
    promo_type(where: {}) {
      value
      comment
    }
  }
`;

// @ts-ignore
export const listPromos = gql`
  query listPromos {
    promotion(order_by: { created_at: asc }) {
      created_at
      description
      does_not_expire
      expired_at
      id
      limit
      name
      promo_code
      promo_type
      sibilings_promotion_id
      is_active
    }
  }
`;

// @ts-ignore
export const getRedeemHistory = gql`
    query getRedeemHistory {
        redeem {
            state
            promotion_id
            promo_type
            phone_number
            id
            created_at
        }
    }

`;
